$bank_info_bg: linear-gradient(167deg, #F0F9FF 6.86%, #D3EEFB 60.33%, #B6DCF0 91.7%);
$tab_bg_color: var(--primary-color);
$tab_border: var(--primary-color);
$view_box_color: #16605a;
$qr_dep_border_color: #0A6E96;

.wallet {
   .qr-container {
      background-color: #ffffff;
      border-radius: .5em;
      width: fit-content;
      margin: auto;
   }

   .qrDep-container {
      border: 1px solid $qr_dep_border_color;
      background-color: #ffffff;
      border-radius: 1.875em;
   }

   .depositAddressBg { 
      background: #E1FFFD;
      border-radius: 1.875em;
   }

   .withdrawal-method-title{
      &.inactive{
         color: #777777;
      }    
      &.active{
         color: #FFFFFF;
      }
   }
}

.status-box{
   color: #ffffff;
   border-radius: 5px;
   &.APPROVED, &.PAID{
       background-color: #039B00;
       border-color: 1px solid #039B00;
   }
   &.REJECTED{
      background-color: #C10808;
       border-color: 1px solid #C10808;
   }
   &.PENDING{
      background-color: #DAA621;
   }
}

.remove-icon{
   width: 50%;
   @media screen and (max-width: 767px) {
      width: 80%;
   }
}

.withdrawmethod{
   .tabContainer{
      border-radius: 3.125em;
      border: 1px solid $tab_border;
      background: #fff;
      backdrop-filter: blur(10px);
   }

   .activeContainer{
      transition: all .5s;
      cursor: pointer;
      border-radius: 3.125em;
      &.active{
         background: $tab_bg_color;
         backdrop-filter: blur(10px);
      }  
    
   }

   .withdrawal-method-title{
      &.inactive{
         color: #777777;
      }    
      &.active{
         color: #FFFFFF;
      }
   }
}

.viewBox{
   background-color: $view_box_color;
   color: #ffffff;
   border-radius: 5px; 
   &:hover{
      background-color: #ffffff;
      color: $view_box_color;
   }
}

.bankInfoContainer{
   border-radius: 0.75em;
   background: $bank_info_bg;

}