$rankDetailsBg: linear-gradient(166.83deg, #96C9C5 6.86%, #5FC1BA 60.33%, #00C4B6 91.7%);

$read_more_text: #16605a;

.dashboard{
    .announcementScrollSection {
        overflow-y: initial;
        max-height: 20em;
    }
    .rankDetailsBg {
        background: $rankDetailsBg;
        border-radius: 1.875em;
    } 

    .read-more-text {
        color: $read_more_text;
        text-decoration: underline;
    }

    .mt5tab{
        // border-bottom: 2px solid #EEF9FF;
        // cursor: pointer;
        // .active{
        //     color: #ffffff;
        // }
        // .inactive{
        //     color: #686868;
        // }
    }
    
    // .activeTab{
    //     background-color: #16605a;
    //     border-radius: 25px;
    // }
}

