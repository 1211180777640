.authincation{
    background: transparent;
}

.login{
    position: relative;
    background-image: url('../images/background/authBg1.webp') !important;
    background-color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
}
.register{
    position: relative;
    background-color: #ffffff;
    background-repeat: no-repeat;
    .authincation{
        background: none;
        .login-aside{
            background-color: transparent;
            &::after{
                display: none;
            }
            min-width: 40%;
        }
    }
}

.login,
.register {
    .auth-btn {
        cursor: pointer;
        position: relative;
        background: #FFFFFF;
        border-radius: 10px;
        -webkit-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
        color: #10616F;

        &:hover{
            background: var(--primary-color-1);
            color: #ffffff;
        }
    }
}

.forgotpassword{
    .content{
        margin-top: 10em;  
    }
}

.termsmodalBody{
    height: 70vh;
    overflow-y: auto;
}

.loginContainer{
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 35, 33, 0.90) ;
    border-radius: 20px;
    .left-container{
        background: url('../images/background/left-container.png');
        background-repeat: no-repeat;
        background-position: left;
        background-size: cover;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border: 1px solid rgba(255,255,255,0.3);
        border-right-width: 0px;
    }
    .right-container{
        background: url('../images/background/rightContainer.png');
        background-repeat: no-repeat;
        background-position: left;
        background-size: cover;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border: 1px solid rgba(255,255,255,0.3);
        border-left-width: 0px;

    }

    @media screen and (max-width: 767px) {
        .left-container{
            border-top-left-radius: 20px;
            border-bottom-left-radius: 0px;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 0px;
        }
        .right-container{
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
        }
    }
    .form-check .form-check-input{
        margin-left: 0px !important;
    }
    .form-check-input{
        top: 0 !important;
    }
}