$qr_container_bg: #ffffff;
$referral_link_box_bg: #E1FFFD;
$rankDetailsBg: linear-gradient(166.83deg, #96C9C5 6.86%, #5FC1BA 60.33%, #00C4B6 91.7%);
$progress_bar_color: #16605A;

.team{
    .rank-icon{
        max-width: 70px;
    }
    .profile-image{
        border-color: #ffffff;
        border-width: 2px;
        border-radius: 3.125em;
        overflow: hidden;
        width: 6em;
        aspect-ratio: 1;
    }
    .qr-container{
        background: $qr_container_bg;
        border-radius: 1.25em;
    }
    .referral-link-box{
        background: $referral_link_box_bg;
        border-radius: 3.125em;
    }
   
}
.team-table{
    border-collapse: separate;
    border-spacing: 0px 10px;
    .user-img{
        border-radius: 50%;
        width: 3em;
        height: 3em;
        object-fit: cover;
    }
    thead {
        tr {
            th {
                border-top: 1px solid #EAECF0 !important;
                border-bottom: 1px solid #EAECF0 !important;

                &:first-child {
                    border-left: 1px solid #EAECF0;
                    border-top-left-radius: 6px;
                    border-bottom-left-radius: 6px;
                }

                &:last-child {
                    border-right: 1px solid #EAECF0;
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                border-top: 1px solid #EAECF0;
                border-bottom: 1px solid #EAECF0;

                &:first-child {
                    border-left: 1px solid #EAECF0;
                    border-top-left-radius: 6px;
                    border-bottom-left-radius: 6px;
                }

                &:last-child {
                    border-right: 1px solid #EAECF0;
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;
                }
            }
        }
    }
    .flag-container{
        border-radius: 50%;
        background: #000;
        height: 3em;
        width: 3em;
        overflow: hidden;
        border: 2px solid #ffffff;
        img{
            object-fit: cover;
        }
    }
    .status{
        &.approved{
            color: #26C63F;
        }
        &.review{
            color: #DAA621;
        }
        &.rejected{
            color: #C10808;
        }
        &.pending{
            color: #6D6D6D;
        }
    }    
    
}

