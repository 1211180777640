.bg{
    position: relative;
    background-image: url('../images/background/authBg1.webp') !important;
    background-color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
    height:100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom:15vh;
}
.preloader-logo {
    width: 10%;
    @media screen and (min-width:677px)  and (max-width:991px){
        width: 20%;
    }

    @media screen and (max-width:676px) {
        width: 40%;
    }

}
.loader {
    width: 48px;
    height: 48px;
    position: relative;
  }
  .loader:before {
      content: '';
      width: 48px;
      height: 5px;
      background: rgba(0,0,0,0.6);
      opacity: 0.25;
      position: absolute;
      top: 60px;
      left: 0;
      border-radius: 50%;
      animation: shadow 0.5s linear infinite;
    }
    .loader:after {
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%);
      animation: bxSpin 0.5s linear infinite;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 4px;
    }
  @keyframes bxSpin {
    17% {
      border-bottom-right-radius: 3px;
    }
    25% {
      transform: translateY(9px) rotate(22.5deg);
    }
    50% {
      transform: translateY(18px) scale(1, .9) rotate(45deg);
      border-bottom-right-radius: 40px;
    }
    75% {
      transform: translateY(9px) rotate(67.5deg);
    }
    100% {
      transform: translateY(0) rotate(90deg);
    }
  }

  @keyframes shadow {
    0%, 100% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.2, 1);
    }
  }
  
