.loading1 {
    .loading-container {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('../images/background/authBg.webp');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .boxes {
        height: 32px;
        width: 32px;
        position: relative;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        margin-top: 32px;
        -webkit-transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
        transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
    }

    .boxes .box {
        width: 32px;
        height: 32px;
        top: 0px;
        left: 0;
        position: absolute;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
    }

    .boxes .box:nth-child(1) {
        -webkit-transform: translate(100%, 0);
        transform: translate(100%, 0);
        -webkit-animation: box1 1s linear infinite;
        animation: box1 1s linear infinite;
    }

    .boxes .box:nth-child(2) {
        -webkit-transform: translate(0, 100%);
        transform: translate(0, 100%);
        -webkit-animation: box2 1s linear infinite;
        animation: box2 1s linear infinite;
    }

    .boxes .box:nth-child(3) {
        -webkit-transform: translate(100%, 100%);
        transform: translate(100%, 100%);
        -webkit-animation: box3 1s linear infinite;
        animation: box3 1s linear infinite;
    }

    .boxes .box:nth-child(4) {
        -webkit-transform: translate(200%, 0);
        transform: translate(200%, 0);
        -webkit-animation: box4 1s linear infinite;
        animation: box4 1s linear infinite;
    }

    .boxes .box>div {
        background: rgba(255, 73, 127, 0.8);
        --translateZ: 15.5px;
        --rotateY: 0deg;
        --rotateX: 0deg;
        position: absolute;
        width: 100%;
        height: 100%;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        -webkit-transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));
        transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));
    }

    .boxes .box>div:nth-child(1) {
        top: 0;
        left: 0;
        background: rgba(255, 173, 127, 0.95); //##4699d3
    }

    .boxes .box>div:nth-child(2) {
        background: rgba(255, 140, 76, 0.7); //##4699d3
        right: 0;
        --rotateY: 90deg;
    }

    .boxes .box>div:nth-child(3) {
        background: rgba(255, 140, 76, 0.7); //##4699d3
        --rotateX: -90deg;
    }

    .boxes .box>div:nth-child(4) {
        background: #DBE3F4;
        top: 0;
        left: 0;
        --translateZ: -90px;
    }

    @keyframes box1 {

        0%,
        50% {
            transform: translate(100%, 0);
        }

        100% {
            transform: translate(200%, 0);
        }
    }

    @keyframes box2 {
        0% {
            transform: translate(0, 100%);
        }

        50% {
            transform: translate(0, 0);
        }

        100% {
            transform: translate(100%, 0);
        }
    }

    @keyframes box3 {

        0%,
        50% {
            transform: translate(100%, 100%);
        }

        100% {
            transform: translate(0, 100%);
        }
    }

    @keyframes box4 {
        0% {
            transform: translate(200%, 0);
        }

        50% {
            transform: translate(200%, 100%);
        }

        100% {
            transform: translate(100%, 100%);
        }
    }
}

.loading2 {
    width: 100%;

    $s: 1.5em;
    $colors: #1D4EF2,
        #FF147F,
        #BF026A;

    @mixin cbox {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @mixin fbox {
        @include cbox;
        width: 100%;
        height: 100%;
    }

    .loading-container {
        // @include cbox;
        transform-style: preserve-3d;
        // perspective: 2000px;
        transform: rotateX(-30deg) rotateY(-45deg);
    }

    .holder {
        @include cbox;
        transform-style: preserve-3d;
        transform: translate3d(0em, $s, $s*.5);

        &:last-child {
            transform: rotateY(-90deg) rotateX(90deg) translate3d(0, $s, $s*.5);
        }

        &:first-child {
            transform: rotateZ(-90deg) rotateX(-90deg) translate3d(0, $s, $s*.5);
        }

        @for $i from 1 through 3 {
            $color: nth($colors, $i);

            &:nth-child(#{$i}) {
                .box {
                    background-color: $color;

                    &:before {
                        background-color: darken($color, 20);
                    }

                    &:after {
                        background-color: darken($color, 10);
                    }
                }
            }
        }
    }

    .box {
        @include cbox;
        transform-style: preserve-3d;
        animation: ani-box 6s infinite;
        width: $s;
        height: $s;

        //opacity: .9;
        &:before,
        &:after {
            position: absolute;
            width: 100%;
            height: 100%;
            content: "";
        }

        &:before {
            left: 100%;
            bottom: 0;
            transform: rotateY(90deg);
            transform-origin: 0 50%;
        }

        &:after {
            left: 0;
            bottom: 100%;
            transform: rotateX(90deg);
            transform-origin: 0 100%;
        }
    }

    @keyframes ani-box {
        8.33% {
            transform: translate3d(-50%, -50%, 0) scaleZ(2);
        }

        16.7% {
            transform: translate3d(-50%, -50%, -$s) scaleZ(1);
        }

        25% {
            transform: translate3d(-50%, -100%, -$s) scaleY(2);
        }

        33.3% {
            transform: translate3d(-50%, -150%, -$s) scaleY(1);
        }

        41.7% {
            transform: translate3d(-100%, -150%, -$s) scaleX(2);
        }

        50% {
            transform: translate3d(-150%, -150%, -$s) scaleX(1);
        }

        58.3% {
            transform: translate3d(-150%, -150%, 0) scaleZ(2);
        }

        66.7% {
            transform: translate3d(-150%, -150%, 0) scaleZ(1);
        }

        75% {
            transform: translate3d(-150%, -100%, 0) scaleY(2);
        }

        83.3% {
            transform: translate3d(-150%, -50%, 0) scaleY(1);
        }

        91.7% {
            transform: translate3d(-100%, -50%, 0) scaleX(2);
        }

        100% {
            transform: translate3d(-50%, -50%, 0) scaleX(1);
        }
    }

    .loading {
        transform: translateY(180%);
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
        letter-spacing: 0.01em;
        color: var(--primary-color-1);
        opacity: 0.5;

        span {
            display: inline-block;
        }
    }

    .loading03 {
        span {
            animation: loading03 .7s infinite alternate;

            @for $i from 1 through 6 {
                &:nth-child(#{$i+1}) {
                    animation-delay: #{$i*.1}s;
                }
            }
        }
    }

    @keyframes loading03 {
        0% {
            transform: scale(1);
        }

        100% {
            transform: scale(.8);
        }
    }


}

// $color: #00FFC0;
$color: #16605a;

.loading3 {
    .preloader-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .progress-1 {
        width: 10em;
        height: 1em;
        border-radius: 20px;
        background:$color;
        animation: p3 2s infinite;
        -webkit-animation: p3 2s infinite;
    }


    @keyframes p3 {
        100% {
            background-size: 100%;
        }
    }
}

.loading4 {
    .preloader-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .progress-1 {
        width: 10em;
        height: 1em;
        border-radius: 20px;
        background:
            repeating-linear-gradient(135deg, #C19A72 0 10px, #9F7E53 0 20px) 0/0% no-repeat,
            repeating-linear-gradient(135deg, #ddd 0 10px, #eee 0 20px) 0/100%;
        animation: load1 2s infinite;
        -webkit-animation: load1 2s infinite;
    }

    @keyframes load1 {
        100% {
            background-size: 100%;
        }
    }
}

$color1: #244876;
$color2: #122943;
$bgColor: #EEF9FF;

.progress-bar {
    margin: 5px;
    width: 10em;
    background: linear-gradient(135deg, $bgColor 0 10px, $bgColor 0 20px);
    background-repeat: no-repeat;
    overflow: hidden;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 20px;
}

.progress-color {
    background: linear-gradient(135deg, $color1 0 10px, $color2 0 20px);
    background-repeat: no-repeat;
    height: 1em;
    border-radius: 20px;
    -webkit-animation: p3 2s infinite;
    -moz-animation:  p3 2s infinite;
    -ms-animation: p3 2s infinite;
    -o-animation:  p3 2s infinite;
    animation: p3 2s infinite;
}

@keyframes p3 {
    0%{
        width: 0%;
    }
    100% {
        width: 100%;
        background-size: 100%;
    }
}
