$qr_container_bg: #ffffff;
$referral_link_box_bg: #E1FFFD;
$rankDetailsBg: url('../images/background/rankBg2.png');
$progress_bar_color: #00FFC0;
$progress_background: #E7E7E7;

.rankinfov2 {
    .rankBg2 {
        // border: 1px solid rgba(255, 255, 255, 0.20);
        background: rgba(0,0,0,0.3);
        filter: blur(0px);
        backdrop-filter: blur(18.5px);
        border-radius: 1em;
    }

    .rankDetailsBg {
        border: 1px solid rgba(255, 255, 255, 0.20);
        background: $rankDetailsBg;
        background-size: 100% 100%;
        background-position: top;
        background-repeat: no-repeat;
        border-radius: 1em;
        box-shadow: 0px 20px 25px 0px rgba(76, 103, 100, 0.10);
    }

    .rank_name {
        color: #fff;
    }

    .rank_desc {
        color: #fff;
    }

    .ranking-container {
        width: 80px;
        height: auto;
        aspect-ratio: 0.7;

        img {
            width: 80px;
            height: auto;
            aspect-ratio: 0.7;
        }
    }
}


.progress {
    background: $progress_background;
    background-color: $progress_background !important;
    justify-content: flex-start;
    border-radius: 10px;
    align-items: center;
    position: relative;
    display: flex;
    height: 10px;
    width: 100%;
}

.progress-value {
    width: 0%;
    animation: load 3s normal forwards;
    box-shadow: 0 10px 40px -10px $progress_bar_color;
    border-radius: 10px;
    // background: #FF147F;
    background: $progress_bar_color;
    background-size: 200% 200%;
    height: 10px;
    transition-duration: 1s;
    transition-property: width;
    transition-timing-function: linear;
    animation: gradient 1s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.prevArrow {
    left: 0;
    top: 50%;
}

.nextArrow {
    right: 0;
    top: 50%;
}