.tutorial{
    .img-container {
        position: relative;
        border-radius: 1.25em;
        overflow: hidden;
        // max-height: 25em;
        width: 100%;
        img{
            // height: ;
            width: 100%;
            object-fit: cover;
            aspect-ratio: 2;
        }
    }

    .img-container .after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #000000 26.87%, rgba(0, 0, 0, 0) 100%);
    }
    .hedge-container{
        
    }

    .sub-content{
        cursor: pointer;
        position: relative;
        border-radius: 16px;
        background: url('../images/background/hedgeBg.webp');
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        border: .4px solid rgba(255,255,255,0.3);
        width: 100%;
        .small-img-container{
            border-radius: 11px;
            overflow: hidden;
            position: relative;
            height: 200px;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
            .after{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 26.87%, rgba(0, 0, 0, 0.3) 100%);
            }
            .desc-content{
                border-bottom-left-radius: 1.25em;
                border-bottom-right-radius: 1.25em;
                min-height: 20%;
                background: #FFFFFF;
            }
        }
        
        
    }

    .accordion-header-pink{
        background-color: #ff4298 !important;
        border-radius: 5px;
        color: #ffffff;
        font-size: 1em;
        font-weight: 500;
        // transition: all .3s ease-in;
        // -webkit-transition: all .3s ease-in;
        @media screen and (max-width: 320px) {
            font-size: 2.75vw;
        }
    }
    .accordion-header-blue{
        // background-color: #4a71f4 !important;
        background-color: #ffffff !important;
        // transition: all .3s ease-in;
        // -webkit-transition: all .3s ease-in;
        // box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
        color: var(--page-title);
        font-size: 1em;
        font-weight: 500;
        @media screen and (max-width: 320px) {
            font-size: 2.75vw;
        }
    }

    .accordion-body-text{
        border: 1px solid #ffa0cb;
        border-radius: 5px;
        // box-shadow: 0px 20px 50px rgba(255, 66, 152, 0.09);
    }
}
.videoModal{
    .modal-content{
        border-radius: 1.25em;
        overflow: hidden;
        background-color: transparent;
    }
    .modalHeader{
        background: #16605a;
    }
}

.pagination-dot{
    width: 20px;
    height: 5px;
    background-color: #8d8d8d;
    border-radius: 10px;
    &.active{
        background-color: #16605a;
    }
}

.hedge-video {
    width: 100%;
    /* Additional styling for the container if needed */
}

.responsive-video {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
}