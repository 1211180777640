$border_right_color: #16605a;
$background: url('../images/background/sidebarBg.webp');
$header_bg: #003C38;
$menu_active_text: #000;
$menu_inactive_text: rgba(255, 255, 255, 1);
$mm_collapse_bg: transparent;
$border-width: 0px;

.ai-icon {
    display: flex !important;
    align-items: center !important;
    border-radius: 0px;
}

.icon-active {
    display: none;
}

.mm-parent-active,
.mm-active {
    .icon-active {
        display: inline-block !important;
    }

    .icon-inactive {
        display: none !important;
    }
}



.parentMenu {
    display: flex;
    align-items: center;
}

.deznav {
    .metismenu {
        padding-bottom: 100px;
        // overflow-y: auto;

        li {
            a {
                color: $menu_inactive_text !important;

                &::before {
                    width: 0px !important;
                }
            }

            .nav-text.parent {
                color: $menu_inactive_text !important;
            }

            &.mm-active {
                .ai-icon {
                    border-radius: 4px !important;
                    background: linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%) !important;
                    box-shadow: none !important;

                    .nav-text {
                        color: $menu_inactive_text !important;
                    }

                    .nav-text.parent {
                        color: $menu_active_text !important;
                    }
                }
            }

            &.mm-parent-active {
                .ai-icon {
                    border-radius: 4px !important;
                    background: linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%) !important;
                    color: #fff !important;
                    box-shadow: none !important;
                }
            }

            &.menu-active {
                .arrow{
                    height: 15px ;
                }
                a {
                    // color: $menu_active_text !important;
                    // background: var(--primary-color) !important;
                    box-shadow: none !important;
                    background: var(--Linear, linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%));
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                &:hover {
                    a {
                        box-shadow: none !important;
                        background: var(--Linear, linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%));
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    }

                    .icon-active {
                        display: inline-block;
                    }

                    .icon-inactive {
                        display: none;
                    }

                }
            }

            &.menu-inactive {
                a {
                    color: $menu_inactive_text !important;
                    // background: var(--primary-color) !important;
                    box-shadow: none !important;
                }

                &:hover {
                    a {
                        box-shadow: none !important;
                        // background: var(--Linear, linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%));
                        // background-clip: text;
                        // -webkit-background-clip: text;
                        // -webkit-text-fill-color: transparent;
                    }

                    .icon-active {
                        display: inline-block;
                    }

                    .icon-inactive {
                        display: none;
                    }

                }
            }

            .nav-text.parent {
                &:hover {
                    color: #000;
                    // background: var(--Linear, linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%));
                    // background-clip: text;
                    // -webkit-background-clip: text;
                    // -webkit-text-fill-color: transparent;
                }
            }
        }
    }
}

[data-sidebar-position="fixed"][data-layout="vertical"] {

    .deznav {
        background: $background !important;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
    }

    .nav-header {
        background-color: none;
        background: $background;
    }

    .nav-text{
        @media screen and (max-width: 520px) {
            font-size: 1.25em !important;
        }
    }
}

.small-title-text {
    color: var(--menu-small-title);
}

[data-sidebar-style="full"][data-layout="vertical"] {
    .small-title-text {
        padding: .5rem 2.1875rem;
    }
}


@media only screen and (min-width: 48rem) {
    [data-sidebar-style="mini"] {
        .deznav {
            .ai-icon {
                .d-flex {
                    justify-content: center !important;
                }
            }
        }
    }
}

[data-sidebar-style="full"] {
    .menu-toggle {
        .ai-icon {
            .d-flex {
                justify-content: center !important;
            }
        }
    }
}

@media screen and (max-width: 820px) {
    [data-sidebar-position=fixed][data-layout=vertical] .menu-toggle .deznav {
        z-index: 99 !important;
        min-height: 100vh !important;
        top: 0 !important;
    }
}


@media only screen and (min-width: 48rem) {
    [data-sidebar-style="mini"] .deznav .metismenu li>ul {
        box-shadow: none !important;
        border-radius: 10px !important;
        background: var(--primary-color) !important;
    }
}

[data-sidebar-position="fixed"][data-layout="vertical"] .menu-toggle .deznav {
    z-index: 99;
}

[data-sidebar-style=full][data-layout=vertical] .menu-toggle .deznav .metismenu>li:hover>a {
    background: transparent !important;
}

.deznav {
    border-right: $border-width solid $border_right_color;
}

.mobileLogo {
    width: 60%;
    display: none;

    img {
        width: 100%;
    }

    @media screen and (min-width: 481px) and (max-width: 766px) {
        width: 50%;
    }

    @media screen and (max-width: 820px) {
        display: block;
    }
}

.mm-collapse {
    background-color: $mm_collapse_bg !important;
    background: $mm_collapse_bg !important;
    padding-top: 0px;
    padding-bottom: 0px;
}

.deznav .metismenu ul a {
    padding-left: 3rem !important;
}

.deznav .metismenu ul a:hover {
    padding-left: 3.5rem !important;
}

.deznav .metismenu>li.mm-active>a:after {
    border-top: 0.3125rem solid white !important;
    border-left: 0.3125rem solid white !important;
}

.w-80 {
    width: 80%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul{
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.20) 0%,rgba(0, 0, 0, 0.20) 10%, rgba(38, 226, 179, 0.7) 100%)!important;
    backdrop-filter: blur(10px);
    left: 6.5rem;
}