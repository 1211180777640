:root {
    --primary-color: #005650;
    --text-primary: #15389C;
    --text-title: #0A0A0A;
    --text-seconday: #777777;
    --text-error: #F66; //F44336
    --menu-active-text-color: #ffffff;
    // --menu-active-text-color: #4EABEB;
    // --menu-inactive-text-color: #4EABEB;
    --menu-inactive-text-color: #686868;
    --menu-small-title: #939393;
    --page-title: #000000;
    --card-bg: #FCFCFC;
    --gray-800: #1D2939;
    --success: #039B00;
    --light-blue: #4EABEB;
    --text-placeholder: #545454;
    --middle-blue: #005CC6;
    --text-grey-1: #dfdfdf;
    --text-grey-2: #d9f2fc;
    --text-grey-3: #8d8d8d;
}

// linear-gradient(167deg, #F0FFF0 6.86%, #A3D9A5 60.33%, #16605a 91.7%);
$textFieldBg: #E1FFFD;
$main_wrapper_bg: #eef9ff;
$primary_btn_radius: 4px;
$primary_btn_bg: linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%);
$primary_btn_box_shadow: 0px 3.556px 7.111px 0px rgba(0, 0, 0, 0.50);
$scrollbar: linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%);
$primary_btn_border: none;
$group_label_radius: 14px;
$group_label_input_bg: #F1F1F1;
$group_label_input_bg_focus: #005650;
$primary_btn_bg_hover: linear-gradient(180deg, #C19A72 -7.13%, #E2D2B1 7.36%, #9F7E53 96.18%);
$card_custom_bg: linear-gradient(167deg, #F0FFF0 6.86%, #A3D9A5 60.33%, #63B76C 91.7%);
$border-right-box-color: #F0FFF0;
$odd_color: transparent;
$even_color: transparent;
$view_more_color: #fff;
$select_dropdown_arrow_container: #9F7E53;
// $select_dropdown_arrow_container: linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%);
$text-input: #686868;
$request_code_btn_bg: linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%);
$request_code_btn_bg_hover: linear-gradient(180deg, #C19A72 -1.13%, #E2D2B1 14.36%, #9F7E53 103.18%);
$request_code_btn_bg_radius: 14px;
$request_code_btn_border_color: #9F7E53;
$body_color: #00110D;

body {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    line-height: 1 !important;
    background-color: #002321 !important;
    touch-action: pan-x pan-y;

    // background-image: url('../images/background/bodyBg2.webp');
    // background-size: 100% auto;
    // background-repeat: no-repeat;
    // background-position: top;
}

.navigation-backdrop-control {
    z-index: -9;
}

[data-sidebar-style="overlay"] {
    .navigation-backdrop-control {
        z-index: 9;

        &.d-none {
            z-index: -1 !important;
        }
    }

    .bg-semi-transparent {
        background: rgba(0, 0, 0, 0.4);
    }
}

#main-wrapper {
    overflow: auto !important;
    // background: transparent !important;

    background-image: url('../images/background/bodyBg2.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;

    // background: $main_wrapper_bg;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: top;
}

.fixedPosition {
    overflow-y: hidden;
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-800 {
    font-weight: 800;
}

.font-weight-900 {
    font-weight: 900;
}

.line-height-18 {
    line-height: 1.125em;
}

.line-height-20 {
    line-height: 1.25em;
}

.line-height-22 {
    line-height: 1.375em;
}

.line-height-24 {
    line-height: 1.5em;
}

.line-height-30 {
    line-height: 1.875em;
}

.line-height-36 {
    line-height: 2.1875em;
}

.line-height-39 {
    line-height: 2.4375em;
}

.line-height-110percent {
    line-height: 110%;
}

.font-8 {
    font-size: 0.5em;
}

.font-10 {
    font-size: 0.625em;
}

.font-12 {
    font-size: 0.75em;
}

.font-14 {
    font-size: 0.875em !important;
}

.font-16 {
    font-size: 1em !important;
}

.font-18 {
    font-size: 1.125em;
}

.font-19 {
    font-size: 1.1875em;
}

.font-20 {
    font-size: 1.25em;
}

.font-22 {
    font-size: 1.375em;
}

.font-24 {
    font-size: 1.5em;
}

.font-25 {
    font-size: 1.5625em;
}

.font-26 {
    font-size: 1.625em;
}

.font-28 {
    font-size: 1.75em;
}

.font-30 {
    font-size: 1.875em !important;
}

.font-32 {
    font-size: 2em;
}

.font-34 {
    font-size: 2.125em;
}

.font-35 {
    font-size: 2.1875em;
}

.font-40 {
    font-size: 2.5em;
}

.font-50 {
    font-size: 3em;
}

.primary-text {
    color: var(--primary-color);
}

.text-light-blue {
    color: var(--light-blue);
}

.text-input {
    color: $text-input;
}

.text-black-1 {
    color: var(--text-title);
}

.text-grey-1 {
    color: var(--text-seconday);
}

.text-grey-2 {
    color: #4C506B;
}

.text-grey-3 {
    color: #A9ACBB;
}

.text-grey-4 {
    color: #6D6D6D;
}

.text-grey-5 {
    color: var(--text-grey-1);
}

.text-grey-6 {
    color: var(--text-grey-2);
}

.text-grey-7 {
    color: var(----text-grey-3);
}

.text-grey-8 {
    color: #686868;
}

.text-grey-9 {
    color: #808080;
}

.placeholder-color{
    color: var(--text-placeholder) !important;
}


.text-blue {
    color: var(--primary-color-2);
}

.text-pink {
    color: var(--primary-color-1);
}

.text-red {
    color: #D40000;
}

.text-green {
    color: var(--success);
}

.text-menu-inactive-text-color {
    color: var(--menu-inactive-text-color)
}

.letter-spacing-03p {
    letter-spacing: 0.3px;
}

.letter-spacing-05p {
    letter-spacing: 0.5px;
}

.letter-spacing--001em {
    letter-spacing: -0.01em;
}

// .input-group {
//     border-radius: 8px;
//     overflow: hidden;
//     background-color: #FCFCFC;
//     align-items: center;
//     border: 1px solid #F3F3F3;

//     &:hover,
//     &:focus,
//     &:active {
//         border: 1.5px solid #000000;
//     }

//     .icon {
//         width: 50%;
//     }
// }

.link-hash{
    color: #fff !important;
    &:hover,
    &:active {
        color: #DDB590 !important;
    }
}

.link-primary {
    color: #000 !important;

    &:hover,
    &:active {
        color: var(--primary-color) !important;
    }

    &.forgotPassword {
        color: #DDB590 !important;
        // color: #ffffff !important;

        &:hover,
        &:active {
            color: #DDB590 !important;
            opacity: 0.8;
        }
    }
}

.input-group-select {
    border: 1px solid #828282 !important;
}

.input-transparent {
    border: none !important;
    background-color: transparent !important;
    background: transparent !important;
    border-radius: 0px !important;
}

.transparent-bg {
    background: transparent;
}

.errorTextRed {
    color: var(--text-error);
}

.requestCodeBtn {
    background: $request_code_btn_bg;
    border: 1px solid $request_code_btn_border_color;
    border-radius: $request_code_btn_bg_radius;

    &:hover,
    &:active,
    &:focus {
        background-color: $request_code_btn_bg_hover;
        border: 1px solid $request_code_btn_border_color;
        // linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%)
        box-shadow: #C19A72 3px 3px 6px 0px inset, #E2D2B1 -3px -3px 6px 1px inset;
    }

    .btnText {
        color: #000;
    }

    &.round {
        border-radius: 100px;
    }
}

.requestCodeBtn.green {
    background: #005650;
    border: 1px solid #005650;
    border-radius: $request_code_btn_bg_radius;

    &:hover,
    &:active,
    &:focus {
        background-color: rgba(0, 86, 80, 0.8);
        border: 1px solid rgba(0, 86, 80, 0.8);
    }

    .btnText {
        color: #fff;
    }
}

.page-title-color {
    color: var(--page-title);
}

.opacity-07 {
    opacity: 0.7;
}

.min-height-350 {
    min-height: 350px;
}

.primary-btn,
.swal-button--confirm {
    background: $primary_btn_bg;
    border-radius: $primary_btn_radius;
    transition: transform 500ms ease-out;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border: $primary_btn_border;
    color: #000;


    // &::before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: -100%;
    //     width: 100%;
    //     height: 100%;
    //     background: linear-gradient(90deg, transparent, #fff, transparent);
    //     transition: 0.5s;
    //     transform: rotate(-45deg);
    // }


    &:hover,
    &:active,
    &:focus {
        // box-shadow: 0 0.5em 0.5em -0.4em #C19A72;
        box-shadow: #C19A72 3px 3px 6px 0px inset, #C19A72 -3px -3px 6px 1px inset;
        transform: translateY(-0.25em);
    }

    .btnText {
        color: #000;
        font-size: 1.25em;
        font-style: normal;
        font-weight: 600;
        line-height: 1.875em;
        letter-spacing: 0.28px;
        white-space: nowrap !important;
    }

    &.round {
        border-radius: 100px;
    }

}

.cancel-btn,
.swal-button--cancel {
    background: #686868;
    border: 1px solid #ffffff;
    border-radius: $primary_btn_radius;
    transition: transform 500ms ease-out;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    color: #fff;
}

.swal-icon--warning__dot,
.swal-icon--warning__body {
    background-color: var(--primary-color);
}

.swal-icon--warning {
    border-color: var(--primary-color) !important;
}

.swal-icon--info {
    border-color: #f27474;

    &::before,
    &::after {
        background-color: #f27474;
    }
}


.delete-btn {
    background: rgb(24, 23, 22);
    border-radius: 100px;
    transition: transform 500ms ease-out;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid rgba(244, 67, 54, 1);
    box-shadow: 0px 2px 0 rgba(24, 23, 22, 0.05), 2px 4px 6px rgba(24, 23, 22, 0.02);

    &:hover {
        background: rgba(24, 23, 22, 0.8);
        border: 1px solid rgba(244, 67, 54, .5);
        box-shadow: 0px 2px 0 rgba(24, 23, 22, 0.05), 2px 4px 6px rgba(0, 0,022, 0.02);
        transition: all 250ms linear;
    }
}


.btnGrey {
    cursor: pointer;
    background: rgba(130, 130, 130, 0.2);
    border-radius: 10px;
    transition: transform 500ms ease-out;
    border: none;

    &::after {
        border-radius: 10px;
        position: absolute;
        content: "";
        width: 0;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: #DDDDDD;
        border-radius: 5px;
        transition: all 0.3s ease;
    }

    &:hover,
    &:active,
    &:focus {
        background: rgba(130, 130, 130, 0.8);
        transform: translateY(-0.25em);

        &::after {
            border-radius: 10px;
            right: 0;
            width: 100%;
        }
    }
}

// a {
//     &:hover,
//     &:active,
//     &:focus {
//         color: var(--primary-color) !important;
//         opacity: 0.8;
//     }
// }


.errorBorder {
    border: 1.5px solid var(--text-error) !important;
}

.card-custom-bg {
    background: transparent;
    // background: linear-gradient(167deg, #E1FFFD 6.86%, #E1FFFD 60.33%, #E1FFFD 91.7%);
    // backdrop-filter: blur(10px);
    // border-radius: 1.25em;
}

.card-white {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    border-radius: 3.125em;
}

.card-grey-2 {
    background: var(--text-grey-2);
    backdrop-filter: blur(10px);
    border-radius: 3.125em;
}

.card-light-blue {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    border-radius: 3.125em;
}

.card-middle-blue {
    background: var(--middle-blue);
    backdrop-filter: blur(10px);
    border-radius: 3.125em;
}

.card-blue {
    background: #102E94;
    backdrop-filter: blur(10px);
    border-radius: 2em;

    .text-number-container {
        background: #1A237B;
        border-radius: 2em;
    }

    &:hover {
        background: #005CC6;

        .text-number-container {
            background: #004798;
            border-radius: 2em;
        }
    }
}

.card-lightBlue {
    background: var(--light-blue);
    // backdrop-filter: blur(10px);
    border-radius: 2em;
}

.text-gray-800 {
    color: var(--gray-800);
}

.custom-dropdown-container {
    .custom-dropdown {
        background: #ffffff;
        border-radius: 5px;
    }

    .custom-dropdown-menu {
        left: 50%;
        transform: translateX(-50%) !important;
        display: none;
        border-radius: 4px;
        margin-top: 8px;
        width: auto;
        padding: 10px;
        box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3);
        background: #ECECEC;
        border: 1px solid #ECECEC;
        transform-origin: top left;
        z-index: 9999;
        position: absolute;
        top: 100%;
        overflow: scroll;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

    .custom-dropdown-menu span {
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        transition: background 0.3s ease;
    }

    .custom-menu-container {
        height: auto;
        overflow-y: scroll;
        flex-direction: column;
        display: flex;

        .lang-text {
            transition: all .5s ease-in;
            color: var(--gray-800);
        }

        @media screen and (max-width: 767px) {
            height: 50%;
        }
    }

    .custom-dropdown-menu span:hover {
        background: var(--primary-color-1);
        border-radius: 5px;
        color: #ffffff;
    }

    #openDropdown-lg:checked+.custom-dropdown-menu {
        display: flex;
        animation: openDropDown 0.4s ease 0s 1 forwards;
    }

    @keyframes openDropDown {
        from {
            transform: rotateX(50deg);
        }

        to {
            transform: rotateX(0deg);
        }
    }
}

.break-white-space {
    white-space: break-spaces !important;
}

.break-white-xs-space {
    @media screen and (max-width: 667px) {
        white-space: break-spaces !important;
    }
}

.row {
    &.display-flex1 {
        display: flex;
        flex-wrap: wrap;

        [class*='display-flex-col1'] {
            flex-grow: 1;
        }
    }
}

.cancelPublishBtn {
    background: #ffffff;
    border: 1px solid #FF147F;
    border-radius: 5px;
    transition: transform 500ms ease-out;
    color: #FF147F;
    cursor: pointer;

    &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: #FF147F;
        transition: all 0.3s ease;
    }

    &:hover,
    &:active,
    &:focus {
        transform: translateY(-0.25em);
        color: #ffffff;

        &::after {
            right: 0;
            width: 100%;
        }
    }
}

.cancelPublishBtnBlue {
    background: #ffffff;
    border: 1px solid var(--primary-color-2);
    border-radius: 5px;
    transition: transform 500ms ease-out;
    color: var(--primary-color-2);
    cursor: pointer;

    &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: var(--primary-color-2);
        transition: all 0.3s ease;
    }

    &:hover,
    &:active,
    &:focus {
        transform: translateY(-0.25em);
        color: #ffffff;

        &::after {
            right: 0;
            width: 100%;
        }
    }
}


.image-container {
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    line-height: 1;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
    background-repeat: no-repeat;
    background-image:
        repeating-linear-gradient(45deg, #828282, #828282 10px, transparent 10px, transparent 20px), // left
        repeating-linear-gradient(135deg, #828282, #828282 10px, transparent 10px, transparent 20px), // top
        repeating-linear-gradient(225deg, #828282, #828282 10px, transparent 10px, transparent 20px), // right
        repeating-linear-gradient(315deg, #828282, #828282 10px, transparent 10px, transparent 20px); // bottom;

    &.redBorder {
        background-image:
            repeating-linear-gradient(45deg, #C10808, #C10808 10px, transparent 10px, transparent 20px), // left
            repeating-linear-gradient(135deg, #C10808, #C10808 10px, transparent 10px, transparent 20px), // top
            repeating-linear-gradient(225deg, #C10808, #C10808 10px, transparent 10px, transparent 20px), // right
            repeating-linear-gradient(315deg, #C10808, #C10808 10px, transparent 10px, transparent 20px); // bottom;
    }
}

.greyBtn {
    cursor: pointer;
    background: #eeeeee;
    mix-blend-mode: normal;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    color: var(--menu-inactive-text-color);

    &:hover {
        background: #bebebe;
        color: #ffffff;

        svg path {
            fill: #ffffff;
        }
    }

}

.documentContainer {
    margin: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PDFDocument {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PDFPage {
    box-shadow: 0 0 8px rgba(255, 20, 127, 0.2);
    ;
}

.PDFPageOne {
    margin-bottom: 25px;
}

.PDFPage>canvas {
    max-width: 100%;
    height: auto !important;
}


.controls-prev,
.controls-next {
    &.active {
        svg path {
            fill: #FF147F;
        }
    }
}

.requestCodeBtnBlue {
    background: #1D4EF2;
    border: 1px solid #1D4EF2;
    border-radius: 5px;

    &:hover,
    &:active,
    &:focus {
        background-color: rgba(29, 78, 242, 0.8);
        border: 1px solid rgba(29, 78, 242, 0.8);
        box-shadow: 0 0.5em 0.5em -0.3em rgba(29, 78, 242, 0.4);
    }
}

.pagination {
    justify-content: center;
    align-items: center;
}

.pagination a {
    padding: 5px 10px;
    border-right: none;
    border-left: none;
    background: transparent !important;
    border-radius: 6px;
    margin: 0px 3px;
    color: #FFF !important
}

.pagination__previouslink,
.pagination__nextlink {
    // border-radius: 50% !important;
    color: rgba(235, 235, 235, 0.60) !important;
}

.pagination__link--active {
    border-radius: 4px;
    // padding: 10px 5px;

    a {
        border-radius: 4px;
        background: #00FFC0 !important;
        color: #005650 !important;
        padding: 5px 10px;
    }
}

.previous,
.next {
    width: 3rem;
    text-align: center;
}

.grayscale {
    filter: grayscale(100%);
}

.smallAvatarContainer {
    background-color: #f2f2f2;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    width: 30px;
    aspect-ratio: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &.empty {
        background-color: #1D4EF2;
        box-shadow: 0px 0px 5px rgba(29, 78, 242, 0.2);
    }
}

.smallAvatarUrl {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.PhoneInputInput {
    border: none !important;
    background-color: transparent !important;
    background: transparent !important;
    font-weight: 400;
    font-size: 0.875em;
    color: var(--text-primary);
    padding: 0.3125rem 1.25rem;
    height: 3.5rem;

    @media only screen and (max-width: 87.5rem) {
        height: 2.75rem;
    }
}

.countryImg {
    width: 2.5em;
    aspect-ratio: 1;
    border-radius: 50%;
}

.smallCountryImg {
    width: 1.5em;
    aspect-ratio: 1.5;
}

.starImg {
    width: 2em;
    filter: grayscale(1);

    &.active {
        animation: svg-shadow 1.5s ease-in-out infinite alternate;
    }
}

@keyframes svg-shadow {
    from {
        filter: drop-shadow(0 0 3px #fff) drop-shadow(0 0 3px rgba(255, 20, 127, 0.3)) drop-shadow(0 0 3px rgba(255, 20, 127, 0.5));
    }

    to {

        filter: drop-shadow(0 0 5px #fff) drop-shadow(0 0 5px rgba(255, 20, 127, 0.3)) drop-shadow(0 0 5px rgba(255, 20, 127, 0.5));
    }
}

@keyframes glowing {
    0% {
        box-shadow: 0 0 -28px 3px #99B900;
    }

    40% {
        box-shadow: 0 0 15px 3px #99B900;
    }

    60% {
        box-shadow: 0 0 15px 3px #99B900;
    }

    100% {
        box-shadow: 0 0 -28px 3px #99B900;
    }
}

.video-container {
    background-color: #000000;
    display: flex;
    justify-content: center;
}

.video {
    width: 100%;
    // height: 80vh;
    object-fit: cover;
}

.video-bottom {
    background-color: #ffffff;
}

.groupLabel-select {
    border-radius: $group_label_radius;
    border: 2px solid #ffffff;
    background: $group_label_input_bg;

    &:hover,
    &:focus,
    &:active {
        background: #ffffff;
        border: 2px solid $group_label_input_bg_focus;
    }
}

.groupLabel-select-round {
    border-radius: 100px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);

    &:hover,
    &:focus,
    &:active {
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid rgba(255, 255, 255, .5);
    }

    &.errorInput {
        border: 1px solid #F66;
        background: rgba(255, 102, 102, 0.40);
    }
}

.groupLabel-input {
    border-radius: $group_label_radius;
    border: 2px solid #ffffff;
    background: $group_label_input_bg;

    &:hover,
    &:focus,
    &:active {
        background: #ffffff;
        border: 2px solid $group_label_input_bg_focus;
    }

    .icon {
        width: 100%;
        padding-right: 8px;
    }

    &.overflow {
        overflow: initial;
    }

    &.date{
        border-radius: 10px !important;
    }
}

.groupLabel-input-round {
    border-radius: 100px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);

    &:hover,
    &:focus,
    &:active {
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid rgba(255, 255, 255, .5);
    }

    .icon {
        width: 100%;
        padding-right: 8px;
    }

    &.overflow {
        overflow: initial;
    }

    &.errorInput {
        border: 1px solid #F66;
        background: rgba(255, 102, 102, 0.40);
    }
}

.groupDisabled-input {
    border-radius: $group_label_radius;
    overflow: hidden;
    background: #e0e0e0;
    align-items: center;
    border: 2px solid #ffffff;

    .icon {
        width: 100%;
        padding-right: 8px;
    }
}

.input-group {
    border-radius: 8px;
    overflow: hidden;
    background-color: transparent;
    align-items: center;
    border: 1px solid transparent;

    &:hover,
    &:focus,
    &:active {
        border: 1px solid transparent;
    }

    .icon {
        width: 100%;
        padding-right: 8px;
    }
}

.form-control {
    padding-left: 0px !important;
    padding-right: 0px !important;
    height: 2.2em !important;
    font-weight: 600 !important;
    font-size: 1.125em !important;
    color: var(--text-title) !important;
}

input::placeholder {
    color: var(--text-placeholder) !important;
    text-transform: capitalize !important;
}

.input-transparent.loginInput::placeholder {
    color: #fff !important;
}

.border-white {
    border-left: 2px solid #FFFFFF;
    border-right: 2px solid #FFFFFF;
}

.border-right-white {
    border-right: 2px solid #FFFFFF;
    border-bottom: none;

    @media only screen and (max-width: 667px) {
        border-right: none;
        border-bottom: 2px solid #FFFFFF;
    }
}

.border-right-box {
    border-right: 2px solid $border-right-box-color;
    border-bottom: none;

    @media only screen and (max-width: 667px) {
        border-right: none;
        border-bottom: 2px solid $border-right-box-color;
    }
}

.overflow-wrap-anywhere {
    overflow-wrap: anywhere;
}

.boxIconSize {
    width: 70px;

    @media screen and (max-width: 667px) {
        width: 70px;
    }

    @media screen and (max-width: 300px) {
        width: 50px;
    }
}

.noDataImgSize {
    // width: 100%;
}

.webkit-app-auto {
    -webkit-appearance: auto !important;
}

.logoSize {
    width: 30%;

    @media screen and (max-width: 992px) {
        width: 60%;
    }

    @media screen and (max-width: 677px) {
        width: 60%;
    }
}

.mobileLogo {
    width: 75%;
    display: none;

    img {
        width: 100%;
    }

    @media screen and (min-width: 481px) and (max-width: 766px) {
        width: 50%;
    }

    @media screen and (max-width: 767px) {
        display: block;
    }
}

.header-icon2 {
    width: 18px;
    height: 18px;
}

.resumeTradingPanel {
    background-color: $primary_btn_bg;
    width: 100%;
    z-index: 2;

    span {
        color: #fff;
        line-height: 18px;
    }

    .mailToLink {
        color: #fff;
        text-decoration: underline;

        &:hover,
        &:focus,
        &:active {
            color: #fff;
            text-decoration: underline;
        }
    }
}

.css-1wa3eu0-placeholder {
    color: var(--text-placeholder) !important;
}

.statusBox {
    border-radius: 5px;

    &.PENDING {
        border: 2px solid darkgrey;
    }

    &.REJECTED {
        border: 2px solid red;

    }

    &.APPROVED {
        border: 2px solid #25C6C6;
    }

    &.COMPLETED {
        border: 2px solid #25C6C6;
    }

    &.unverified {
        background: #eff2f6;
        border: 1px solid #eff2f6;
        color: #525b75;
    }

    &.success,
    &.completed,
    &.active,
    &.approved,
    &.verified {
        color: #1C6C09;
        background: #D9FBD0;
        border: 1px solid #D9FBD0;
    }

    &.failed,
    &.rejected, &.deleted {
        background: #FFE0DB;
        border: 1px solid #FFE0DB;
        color: #B81800;
    }

    &.pending {
        background: #ffefca;
        border: 1px solid #ffefca;
        color: #bc3803;
    }

}

// .status {
//     &.PENDING {
//         color: darkgrey;
//     }

//     &.REJECTED {
//         color: red;
//     }

//     &.APPROVED {
//         color: green;
//     }

//     &.COMPLETED {
//         color: green;
//     }
// }

.orangeContainer {
    border-radius: 0.75em;
    background: var(--Linear, linear-gradient(167deg, #FFF6F0 6.86%, #FFF7F2 60.33%, #FFECDE 91.7%));
}

.blurContainer {
    border-radius: 1em;
    border: 1px solid #FFF;
    background: linear-gradient(153deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: blur(21px);
}

.tableBgContainer {
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: url('../images/background/tableBg.webp');
    backdrop-filter: blur(21px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    z-index: 1;
    position: relative;
}

.graphBgContainer {
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: url('../images/background/graphBg.webp');
    backdrop-filter: blur(21px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
}

.whiteContainer {
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    border-radius: 1.875em;

    .white-container-title {
        color: #000000;
    }

    .textFieldBg {
        background: $textFieldBg;
        border-radius: 1.875em;
    }
}

.whiteContainer1 {
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    border-radius: 1.875em;
    height: 150px;

    @media screen and (max-width:767px) {
        height: 144.38px;

    }

    .white-container-title {
        color: #000000;
    }

    .textFieldBg {
        background: $textFieldBg;
        border-radius: 1.875em;
    }
}

.table-custom {
    border-collapse: separate;
    border-spacing: 0 5px;

    th,
    td {
        border: 0px !important;
    }

    tbody tr {
        margin-bottom: .5em;
    }

    td:first-child {
        border-top-left-radius: 1.875em;
        border-bottom-left-radius: 1.875em;
    }

    td:last-child {
        border-top-right-radius: 1.875em;
        border-bottom-right-radius: 1.875em;
    }

    .rewardIcon {
        width: 75%;

        @media screen and (min-width: 992px) {
            max-width: 2em;
        }
    }

    .firstPlaceBg {
        border-radius: 1.875em;
        background: #1D7E84;
    }

    .secondPlaceBg {
        border-radius: 1.875em;
        background: rgba(29, 126, 132, 0.6);
    }

    .thirdPlaceBg {
        border-radius: 1.875em;
        background: rgba(29, 126, 132, 0.4);
    }

    .totalBg {
        border-radius: 1.875em;
        background: #10616F;

    }
}

.table-list {
    // white-space: nowrap;
    // border-collapse: separate;
    // border-spacing: 0px 10px;
    // white-space: nowrap;
    // border-collapse: separate;
    // margin: 25px 0;

    border-collapse: separate;
    border-spacing: 0px;

    th,
    td {
        border-bottom: 1px solid rgba(235, 235, 235, 0.10);
        position: relative;
        color: #fff;

        // text-align: center;
        // font-size: 1em;
        // padding: 12px 15px;
        &::after {
            content: '';
            position: absolute;
            top: 3px;
            bottom: 3px;
            right: 0;
            width: 1px;
            background: rgba(235, 235, 235, 0.10);
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

    th,
    td {
        &:last-child::after {
            display: none;
        }
    }

    // th:not(:last-child),
    // td:not(:last-child) {
    //     border-right: 1px solid rgba(235, 235, 235, 0.10);
    // }

    thead {
        th {
            border-top: 1px solid rgba(235, 235, 235, 0.40) !important;
            border-bottom: none !important;
        }
    }

    tbody {
        tr:not(:last-child) {
            td {
                border-bottom: 1px solid rgba(235, 235, 235, 0.10) !important;
                border-top: none !important;
            }
        }

        tr:first-child {
            td {
                border-bottom: 1px solid rgba(235, 235, 235, 0.10) !important;
                border-top: 1px solid rgba(235, 235, 235, 0.10) !important;
            }
        }

        tr:last-child {
            td {
                border-top: 1px solid rgba(235, 235, 235, 0.10) !important;
                border-bottom: none !important;
            }
        }
    }

    td {
        padding: 0.6rem 0.625rem !important;

        &.padding-0 {
            padding: 0.4rem 0.625rem !important;
        }

        // &.padding {
        //     padding: 0.72rem 0.625rem !important;

        // }

        color: #fff !important;

        // padding: 1px !important;  
        @media screen and (max-width: 520px) {
            min-width: 150px;

            &.description {
                min-width: 250px;
            }
        }

    }

    // td:first-child {
    //     border-top-left-radius: 1.875em;
    //     border-bottom-left-radius: 1.875em;
    // }

    // td:last-child {
    //     border-top-right-radius: 1.875em;
    //     border-bottom-right-radius: 1.875em;
    // }

    .odd {
        background: $odd_color;
    }

    .even {
        background: $odd_color;
    }
}

.table-rankinfo {
    // white-space: nowrap;
    // border-collapse: separate;
    // border-spacing: 0px 10px;
    // white-space: nowrap;
    // border-collapse: separate;
    // margin: 25px 0;

    border-collapse: separate;
    border-spacing: 0px;


    td {
        border-bottom: 1px solid rgba(235, 235, 235, 0.10);
        position: relative;
        color: #fff;

        // text-align: center;
        // font-size: 1em;
        // padding: 12px 15px;
        &::after {
            content: '';
            position: absolute;
            top: 3px;
            bottom: 3px;
            right: 0;
            width: 1px;
            background: rgba(235, 235, 235, 0.10);
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

    th,
    td {
        &:last-child::after {
            display: none;
        }
    }

    // th:not(:last-child),
    // td:not(:last-child) {
    //     border-right: 1px solid rgba(235, 235, 235, 0.10);
    // }

    thead {
        th {
            // border-top: 1px solid rgba(235, 235, 235, 0.40) !important;
            border-bottom: none !important;
        }
    }

    tbody {
        tr:not(:last-child) {
            td {
                border-bottom: 1px solid rgba(235, 235, 235, 0.10) !important;
                border-top: none !important;
            }
        }

        tr:first-child {
            td {
                border-bottom: 1px solid rgba(235, 235, 235, 0.10) !important;
                border-top: 1px solid rgba(235, 235, 235, 0.10) !important;
            }
        }

        tr:last-child {
            td {
                border-top: 1px solid rgba(235, 235, 235, 0.10) !important;
                border-bottom: none !important;
            }
        }
    }

    td {
        padding: 0.6rem 0.625rem !important;
        color: #fff !important;
        &.padding-0 {
            padding: 0.4rem 0.625rem !important;
        }

        @media screen and (max-width: 520px) {
            min-width: 150px;

            &.description {
                min-width: 250px;
            }
        }

    }

    // td:first-child {
    //     border-top-left-radius: 1.875em;
    //     border-bottom-left-radius: 1.875em;
    // }

    // td:last-child {
    //     border-top-right-radius: 1.875em;
    //     border-bottom-right-radius: 1.875em;
    // }

    .odd {
        background: $odd_color;
    }

    .even {
        background: $odd_color;
    }
}

::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    border-radius: 3.125em;
}

/* Track */
::-webkit-scrollbar-track {
    background: #D5E2E9;
    border-radius: 3.125em;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $scrollbar !important;
    border-radius: 3.125em;
}

/* Handle on hover */
::-webkit-scrollbar-thumb {
    background: $scrollbar !important;
}

.left-arrow {
    position: absolute;
    left: 0%;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
}

.right-arrow {
    position: absolute;
    right: 0%;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%) rotate(180deg);
}

.modal-backdrop.show {
    opacity: 1 !important;
}

.modal-backdrop {
    background: rgba(39, 39, 39, 0.78) !important;
    backdrop-filter: blur(15px);
}

.modalColorBg {
    .modal-content {
        background: url('../images/background/popupBg.webp');
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px;
        overflow-y: hidden;
    }

    .modalBody,
    .modal-body {
        max-height: 80vh;
        overflow-y: auto;
    }

    @media screen and (max-width: 767px) {

        .modalBody,
        .modal-body {
            max-height: 80vh;
            overflow-y: auto;
        }
    }
}

.modalInfoBg {
    .modal-content {
        background: url('../images/background/infoBg.webp');
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px;
        overflow-y: hidden;
    }

    .modalBody,
    .modal-body {
        max-height: 80vh;
        overflow-y: auto;
    }

    @media screen and (max-width: 767px) {

        .modalBody,
        .modal-body {
            max-height: 80vh;
            overflow-y: auto;
        }
    }
}

.swal-modal {
    background: url('../images/background/popupBg.webp');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    overflow-y: hidden;
}

.swal-overlay {
    background: rgba(39, 39, 39, 0.78) !important;
    backdrop-filter: blur(15px);
}

.swal-icon--success:after,
.swal-icon--success:before {
    background: none !important;
}

.swal-icon--success__hide-corners {
    background: none !important;
}

.swal-text,
.swal-title {
    color: #fff;
    text-align: center;
}

.MuiPickersToolbarText-toolbarTxt {
    color: #ffffff !important;
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
    background-color: var(--primary-color) !important;
}

.MuiButton-textPrimary {
    color: var(--primary-color) !important;
}


.form-control {
    &.date {
        .MuiInputBase-root {
            input::placeholder {
                font-weight: 600 !important;
                // font-size: 1em !important;
                color: var(--text-placeholder) !important;
                opacity: 1;
                text-transform: capitalize;
            }

            input:read-only::placeholder {
                color: var(--text-placeholder) !important;
                font-weight: 600 !important;
                // font-size: 1em !important;
                opacity: 1;
            }
        }
    }
}

.MuiInput-formControl .MuiInput-input::placeholder,
.MuiInput-formControl .MuiInputBase-input::placeholder {
    color: var(--text-placeholder) !important;
    font-weight: 600 !important;
    font-size: 1em !important;
}

.MuiInputBase-input {
    color: #000000 !important;
    font-weight: 600 !important;
    // font-size: 1.125em !important;
}

input:read-only::placeholder {
    color: var(--text-placeholder) !important;
    font-weight: 600 !important;
    // font-size: 1.125em !important;
}

.ant-picker-input {
    input::placeholder {
        color: var(--text-placeholder) !important;
        font-weight: 600 !important;
        font-size: 1em;
    }
}

.ant-picker-active-bar {
    background: var(--primary-color) !important;
}

input:read-only {
    font-weight: 600 !important;
    // font-size: 1.125em !important;
}

.MuiInput-underline:before,
.MuiInput-underline:after,
.MuiInput-underline.Mui-error:after {
    border-color: transparent !important;
}

.form-check-input {
    border-radius: 4px !important;
    border-color: #fff !important;
    background-color: transparent !important;
}

.form-check-input:checked {
    background-color: transparent !important;
    border-color: #fff !important;
}

[class^="css-"][class$="-control"],
[class^="css-"][class$="-container"] {
    min-height: 2.2em !important;
    height: 2.2em !important;
}

[class^="css-"][class$="-Svg"] {
    fill: #ffffff;
}

[class^=" css-"][class$="-indicatorContainer"] {
    padding: 1px !important;
    background: $select_dropdown_arrow_container;
    border-radius: 50%;
    margin-left: 5px;
    color: #fff !important;
}

[class^=" css-"][class$="-indicatorSeparator"] {
    width: 0px !important;
}

[class^=" css-"][class$="-placeholder"] {
    font-size: 1.125em;
    color: var(--text-placeholder)
}

.gold-indicatior-container{
    padding: 1px !important;
    background: $select_dropdown_arrow_container;
    border-radius: 50%;
    margin-left: 5px;
    width: 20px;
    height: 20px;
    color: #fff !important;
}

.calendar-icon{
    width: 20px;
    height: 20px;
}

.select-placeholder-center {
    border-radius: 10px !important;

    [class^=" css-"][class$="-ValueContainer"],
    [class^=" css-"][class$="-container"] {
        text-align: center;
        justify-content: center;
    }

    [class^=" css-"][class$="-placeholder"],
    [class^=" css-"][class$="-singleValue"] {
        width: 100%;
        max-width: 100%;
        text-align: center;
        justify-content: center;
    }
}

.groupLabel-select.green {
    [class^="css-"][class$="-Svg"] {
        fill: #ffffff;
        background: #005650 !important;
        border-radius: 50%;
    }

    [class^=" css-"][class$="-indicatorContainer"] {
        padding: 1px !important;
        background: #005650 !important;
        border-radius: 50%;
        color: #fff !important;
    }
}


.transfer-wallet-option {
    @media screen and (max-width: 540px) {
        [class^="css-"][class$="-ValueContainer"] {
            justify-content: center;
        }
    }

}

.redBorder {
    border-radius: 1.875em;
    border: 1px solid red;
}

.login-link {
    &:hover {
        color: var(--primary-color);
        opacity: 0.6;
    }
}

@media only screen and (max-width: 74.9375rem) {
    [data-header-position="fixed"] .content-body {
        padding-top: 5.5rem !important;
    }
}

.overflow-x-auto {
    overflow-x: initial;
}

.bgImgModal {
    .modal-content {
        background-repeat: no-repeat;
        background-size: cover !important;
        border-radius: 3.125em;
    }

    .modalBody {
        max-height: 80vh;
        overflow-y: auto;
    }

    @media screen and (max-width: 767px) {
        .modalBody {
            height: 70vh;
            overflow-y: auto;
        }
    }
}

.view-more-text {
    color: $view_more_color;
    text-decoration: underline;

    &:hover,
    &:active {
        color: $view_more_color !important;
        opacity: 0.8;
        text-decoration: none;
    }
}

.deleteBtn {
    // background: rgba(255, 47, 47, 0.9);
    // border: 1px solid rgba(255, 47, 47, 0.49);
    border-radius: 3.125em;
    color: #fff;
    background: #ec1f00;
    border: .4px solid #FFE0DB;

    &:hover,
    &:active,
    &:focus {
        background-color: rgba(236, 31, 0, 0.8);
        border: .4px solid rgba(236, 31, 0, 1);
        box-shadow: 0 0.5em 0.5em -0.3em rgba(236, 31, 0, 0.4);
    }
}

.disabledDeleteBtn {
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 3.125em;
    color: rgba(0, 0, 0, 0.8);

    &:hover,
    &:active,
    &:focus {
        background-color: rgba(0, 0, 0, 0.4);
        border: 1px solid rgba(0, 0, 0, 0.4);
        box-shadow: 0 0.5em 0.5em -0.3em rgba(0, 0, 0, 0.3);
    }
}

.tableContainerAccordion {
    .odd {
        background: $odd_color;
    }

    .even {
        background: $odd_color;
    }

    .border-top {
        border-top-color: #686868 !important;
        border-top-width: 1px;
    }

    .accordion-item {
        // background: #FFF9F4;
        border-radius: 1.25em;

        .accordion__button {
            padding-bottom: 2px !important;
        }
    }

    .accordion-item-inactive {
        // background: #FFF1E7;
        border-radius: 1.25em;
    }

    // .accordion__button {
    //     background-color: transparent !important;
    // }

    // .accordionTitle {
    //     font-style: normal;
    //     font-weight: 700;
    //     font-size: 1em;
    //     line-height: 29px;
    //     color: #0093FF;

    //     @media (max-width: 667px) {
    //         font-weight: 500;
    //         font-size: 1em;
    //     }

    //     @media (max-width: 300px) {
    //         font-weight: 500;
    //         font-size: 15px;
    //         // line-height: 25px;
    //     }
    // }

    // .accordionSideItemText {
    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 16px;
    //     line-height: 29px;
    //     color: #0093FF;
    //     width: fit-content;

    //     @media (max-width: 667px) {
    //         font-weight: 400;
    //         font-size: 12px;
    //     }
    // }

    // .accordionTitle-inactive {
    //     font-style: normal;
    //     font-weight: 700;
    //     font-size: 16px;
    //     line-height: 29px;
    //     color: #000;

    //     @media (max-width: 667px) {
    //         font-weight: 500;
    //         font-size: 16px;
    //     }

    //     @media (max-width: 300px) {
    //         font-weight: 500;
    //         font-size: 15px;
    //         line-height: 25px;
    //     }
    // }

    // .accordionSideItemText-inactive {
    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 16px;
    //     line-height: 29px;
    //     color: #000;

    //     @media (max-width: 667px) {
    //         font-weight: 400;
    //         font-size: 12px;
    //     }
    // }


    // .accordionContent {
    //     color: #000;

    //     @media (max-width: 992px) {
    //         font-size: 14px;
    //         line-height: 25px;
    //     }

    //     @media (max-width: 677px) {
    //         font-size: 12px;
    //         line-height: 22px;
    //     }
    // }


    // .accordion__button {
    //     background: transparent
    // }

    // .accordion__panel {
    //     padding-left: 8px;
    //     padding-top: 4px !important;
    // }


    .accordion__button:before {
        display: none;
    }

    .lh-1 {
        line-height: 1;
    }

    .accordion__button::before {
        display: none !important;
    }

    .accordionIcon {
        color: #fff;
        font-size: 16px;
    }

    .accordion-inactive {
        background: #777777;
        border-radius: 50%;
        width: 2em;
        height: 2em;
        text-align: center;
    }

    .accordion-active {
        background: #4EABEB;
        border-radius: 50%;
        width: 2em;
        height: 2em;
        text-align: center;
    }

    .accordionLine {
        width: 100%;
        height: 1px;
        color: #123455;
        background-color: #123455;
        border: 1px solid #123455;
    }
}

.transferBox {
    border-radius: 4px;
    background: var(--Linear, linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%));
    box-shadow: 0px 1px 2px 0px rgba(84, 84, 84, 0.50);

    &:hover {
        background: linear-gradient(180deg, #C19A72 -1.13%, #E2D2B1 14.36%, #9F7E53 103.18%);
        opacity: 0.9;
    }
}

.copyBox {
    cursor: pointer;
    border-radius: 4px;
    background: linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%);

    &:hover {
        background: linear-gradient(180deg, #C19A72 -1.13%, #E2D2B1 14.36%, #9F7E53 103.18%);
        opacity: 0.9;
        box-shadow: #C19A72 3px 3px 6px 0px inset, #C19A72 -3px -3px 6px 1px inset;
    }
}

.breakWord {
    word-break: break-all;
}

.strategyBox {
    background: lightgray;
}

.text-underlined {
    text-decoration: underline;
}

.word-keep-all {
    word-break: keep-all;
}

.iconSize {
    height: 40px !important;
}

.rankIconSize {
    height: 70px !important;
}

.bigRankIconSize {
    height: 80px !important;
}

// .rankingFont{
//     font-size: 0.875em !important;
//     @media screen and (max-width:767px) {
//         font-size:16px !important;
//     }   
// }

.ant-picker {
    border-radius: 10px !important;
    background: #fff !important;
    height: 2.8em !important;

    &.border {
        border: 1.5px solid $group_label_input_bg_focus !important;
    }
}



.ant-picker-suffix {
    color: black !important;
}

.ant-picker-input>input {
    color: black !important;
    font-weight: 600 !important;
}

@media screen and (max-width: 667px) {
    .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
        display: block !important;

        .ant-picker-panel {
            display: block !important;
        }
    }

    .ant-picker-panel-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

}


// .ranking-icon{
//     width:6vw;

//     @media screen and (max-width:767px) {
//         // width:25vw;
//         width: 108px;
//     }
// }

.team-rank {
    // width:4vw;
    // height:70px !important;

    // @media screen and (max-width:767px) {
    //     width:15vw;
    //     height:auto !important;

    // }
}

.bg-transparent {
    background: transparent;
}

.comingSoonText {

    font-weight: 600;
    color: black;

    font-size: calc(30px + 0.02vw);
    line-height: calc(21px + 0.02vw);

    @media screen and (max-width: 767px) {
        font-size: calc(25px + 0.1vw);
        line-height: calc(35px + 0.1vw);
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(30px + 0.2vw);
        line-height: calc(21px + 0.2vw);
    }

}

.css-lvqld2-placeholder {
    color: var(--text-placeholder) !important;
    font-weight: 600 !important;
}

.break-all-word {
    word-break: break-all;
}

.ps__rail-y {
    width: auto !important;
}

.tab-container {
    background: rgba(255, 255, 255, 0.60);
    border-radius: 10px;

    .tab-item {
        cursor: pointer;
        background: rgba(255, 255, 255, 0.60);
        mix-blend-mode: normal;
        color: #033640;
        transition: all .5s;

        &:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        &.active {
            background: #1DA684;
            mix-blend-mode: normal;
            color: #fff;
        }
    }

    .tab-item2 {
        cursor: pointer;
        background: linear-gradient(180deg, rgba(255, 255, 253, 0.72) 0%, rgb(225, 255, 253, 0.48) 100%), rgb(225, 255, 253);
        color: #16605a;
        box-shadow: 0px 20px 50px rgba(255, 255, 255, 0.07);
        transition: all .5s;
        border-radius: 5px;

        &.active {
            background: #16605a;
            box-shadow: 0px 20px 50px rgba(255, 255, 253, 0.07);
            color: #fff !important;
        }
    }
}

.rank-info {
    width: 30px;
    z-index: 99;
}

.balanceIcon {
    width: 4.375em;
}

.balanceBox,
.kycBox {
    border-radius: 14px;
    border: 1px solid rgba(255, 255, 255, 0.6);
}

.back-btn {
    width: 2.25em;
}

.pagination-container {
    border-radius: 7px;
    border: 1px solid transparent;
    backdrop-filter: blur(11.5px);

    background: linear-gradient(153deg, rgba(255, 255, 255, 0.22) 0%, rgba(255, 255, 255, 0.00) 100%);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
}

.view-btn {
    border-radius: 30px;
    border: 1px solid #5FAD99;
    background: rgb(0, 126, 131);
    color: #fff;

    &:hover,
    &:active,
    &:focus {
        background-color: rgba(95, 173, 153, .8);
        border: 1px solid rgba(95, 173, 153, .8);
        box-shadow: rgba(0, 126, 131, .2) 3px 3px 6px 0px inset, rgba(0, 126, 131, .2) -3px -3px 6px 1px inset;
    }
}

.status {
    &.unverified {
        color: #fff !important;
    }

    &.success,
    &.completed,
    &.active,
    &.approved,
    &.verified {
        color: #25b003 !important;
        // color: #25C6C6 !important;
    }

    &.pending {
        color: #E5780B !important;
        // color: #DDB590 !important;
    }

    &.failed,
    &.rejected {
        color: #EC1F00 !important;
    }

    &.appealed {
        color: #FF147F !important;
    }

    &.pendingpayment {
        color: #DDB590 !important;
    }

    &.pendingconfirm {
        color: #FF147F !important;
    }


}

.deposit_address_bg {
    background: $group_label_input_bg;
    border-radius: $group_label_radius
}

.deposit-qr-container {
    border: 1px solid var(--primary-color);
    background-color: #ffffff;
    border-radius: 1em;
}

.referral-qr-container {
    background: #ffffff;
}

.verticalBorderLine {
    background: #ffffff;
    width: 1px;
    height: 100%;
}

.borderLine {
    background: #ffffff;
    height: 1px;
    width: 100%;
}

.absolute-rank-icon {
    position: absolute;
    right: 0;
    bottom: -1em;
}

.rank-scrolling-wrapper {
    overflow-x: auto;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.rank-scrolling-wrapper::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.news-border-bottom {
    border-bottom: .4px solid rgba(255, 255, 255, 0.4);
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.pagination-dot-rank {
    background: #D9D9D9;
    width: 10px;
    height: 10px;
    border-radius: 50%;

    &.active {
        background: #00FFBE;
    }
}

.mt5tab {
    // border-bottom: 2px solid #EEF9FF;
    cursor: pointer;

    .active {
        color: #000;
    }

    .inactive {
        color: #fff;
    }
}

.activeTab {
    background: var(--Linear, linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%));
    border-radius: 5px;
}

.border-bottom-md {
    border-bottom: 1px solid rgba(255, 255, 255, 1);
}

.text-gold-gradient {
    background: var(--Linear, linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gold {
    color: #DDB590 !important;
}

.text-light-red {
    color: #F66;
}

.groupLabel-select-round {
    [class^=" css-"][class$=-placeholder] {
        color: #fff !important;
    }

    .css-qhky40-singleValue {
        color: #fff !important;
    }
}

// .errorInput{
//     .input-transparent.loginInput::placeholder {
//         color: #FF6666 !important;
//     }
//     .input-transparent.loginInput{
//         color: #FF6666 !important;
//     }
// }

.overlayDatepicker{
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    left: 0;
    top: 0;
    z-index: 1;
    border-radius: 16px;
}

@media (max-width: 768px) {
    .custom-date-picker input {
      pointer-events: none;
    }
}

.border-bottom-grey {
    border-bottom: .3px solid rgba(255,255,255, 0.6);
}

.daterangepicker {
    z-index: 1 !important;
    border-radius: 10px;
    border: none !important;
    background-color: rgba(0, 0, 0, 1) !important;
    background: linear-gradient(153deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%) !important;
    backdrop-filter: blur(21px);
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
   
    &::before{
        border-bottom-color: rgba(255,255,255,0.6) !important;
    }

    &::after{
        border-right-color: rgba(255,255,255,0.6) !important;
        border-left-color: rgba(255,255,255,0.6) !important;
        border-bottom: none !important;
    }

    &.show-calendar {
        .ranges {
            margin-top: 0px !important;
        }
    }

    .drp-calendar {
        &.left,
        &.right {
            padding: 0px !important;
        }
    }

    .active{
        color: #000 !important;
    }

    .drp-selected {
        color: #ffffff;
        font-weight: 600 !important;
    }

    .applyBtn {
        color: #000 !important;
        border-radius: 5px;
        border: 1px solid #9F7E53 !important;
        background-color: transparent !important;
        background: $primary_btn_bg !important;
    }

    .calendar-table {
        border: none !important;
        background-color: transparent !important;
    }

    .month {
        color: #ffffff !important;
    }

    thead {
        th {
            color: #ffffff !important;
            padding-top: 3px;
            padding-bottom: 3px;
        }
    }

    tbody {
        td {
            border-radius: 0px !important;
            color: #fff;
        }
    }

    .off {
        background-color: transparent !important;
        color: #DBDBDB !important;
    }

    .prev,
    .next {
        span {
            border-color: #ffffff !important;
        }
    }

    .start-date {
        background: linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%) !important;
    }

    .in-range {
        background-color: transparent !important;
        background: linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%) !important;
        color: #000 !important;
    }

    .end-date {
        &.in-range {
            background: linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%) !important;
            background-color: none !important;
        }

    }
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover{
    background-color: transparent !important;
    background: linear-gradient(180deg, #C19A72 -4.13%, #E2D2B1 11.36%, #9F7E53 99.18%) !important;
    color: #000 !important;
    span{
        color: #000 !important;
        border-color: #000 !important;
    }
    
}