.investment {
    #investmentBox1Bg {
        // background-image: url(../images/investment/box1Bg.png);
        background-size: contain;
        background-position: right;
        background-repeat: no-repeat;
    }

    #investmentBox2Bg {
        // background-image: url(../images/investment/box2Bg.png);
        background-size: contain;
        background-position: right;
        background-repeat: no-repeat;
    }

    .activeTab {
        cursor: pointer;
        border-bottom: 2px solid #15389C;

        .tabText {
            color: #15389C;
        }
    }

    .inactiveTab {
        cursor: pointer;
        border-bottom: 1px solid #EEF9FF;

        .tabText {
            color: #686868;
        }
    }
}